import React from 'react';
import '../styles/home.css';
import { useEffect } from 'react';

import TransparentRender from '../assets/Renderv2.png'
import LargeTransparentRender from '../assets/RenderLarge.png'



import FeaturesMin from './FeaturesMin';


const Home = () => {

    return (
        <div name="home" className='w-full bg-gradient-to-r from-gray-600 via-[#0a192f] to-gray-700 background-animate justify-center shadow-2xl md:flex lg:flex  '>
            {/* Container */}
            <div className=''>
                <div className='text-center mx-auto pt-32'>
                    <p className='text-[#f78b8b] text-xl font-semilight'>Live a pain free life</p>
                    <h1 className='mb-4 mt-2 text-3xl text-white font-extrabold lg:text-6xl'>Portable Heating Pad</h1>
                </div>
                {/* Image gallery needs to grow - use carousel? - use 1 image here for the header ?*/}
                <div className='flex object-contain justify-center items-center mt-3'>

                    <img src={TransparentRender} alt="product" className='max-w-full'></img>


                </div>

                <div className='justify-center items-center text-center mt-2 mb-2 lg:mt-1'>
                    <div className='grid grid-rows-1 grid-col-1 auto-cols-max items-center justify-center'>
                        <h1 className='text-xl text-[#f78b8b] font-bold lg:text-2xl'>DONT MISS THIS SALE!</h1>

                    </div>
                    <div className='grid grid-rows-1 grid-col-2 grid-flow-col auto-col-min py-2'>
                        <s className='text-xl text-right mr-2 text-gray-300 font-bold lg:text-2xl'>£24.99</s>
                        <h3 className='text-xl text-left ml-2 text-white font-extrabold lg:text-3xl'>£20.99</h3>
                    </div>
                    {/* Offer and click to action - change padding so it doesn't cause the  */}
                    <div className='justify-center items-center text-center z-50 my-2 py-6 pb-12 lg:py-3 md:py-6'>
                        <a href="https://www.amazon.co.uk/gp/product/B0BTMVRHZ7" target="_blank" rel="noopener noreferrer">

                            <button
                                type="button"
                                className="animate-bounce justify-center items-center rounded-2xl 
                                border-solid border-2 border-[#f45454] lg:px-10 px-4 pb-2 pt-2 lg:text-lg text-m font-semibold 
                                tracking-widest
                                
                                leading-normal text-white 
                                shadow-[0_4px_9px_-4px_#f45454] 
                                transition duration-150 ease-in-out                             
                                hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] 
                                focus:bg-primary-600 
                                focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] 
                                focus:outline-none 
                                focus:ring-0 active:bg-primary-700 
                                active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(159,113,202,0.2)] 
                                dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] 
                                dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(159,113,202,0.1)] 
                                dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(159,113,202,0.1)] 
                                dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(159,113,202,0.1)] 
                                ">
                                Buy Now

                            </button>
                        </a>



                    </div>

                </div>



            </div>



            {/* Feature List*/}









        </div>
    );
};

export default Home;
