import React from 'react'
import JayaAvatar from '../assets/jaya_avatar.png'
import KiskeAvatar from '../assets/kiske_avatar.png'
import FemaleAvatar from '../assets/user.png'


const Testimonials = () => {

    {/**Amazon's orange color used */ }
    const starColor = '#F77737'

    return (


        <div className="flex lg:flex-row h-fit lg:h-screen justify-center items-center w-full align-middle px-6 py-2 md:px-12 lg:px-14 pb-12 bg-white/95">
            <section className="text-center">
                <h1 className='font-bold text-3xl mt-2 lg:text-6xl'>Testimonials</h1>
                <h2 className='font-light text-sm mt-2 mb-6 lg:mb-12 lg:text-xl'>Hear what our previous customers have to say!</h2>

                <div className="grid gap-x-6 md:grid-cols-3 lg:gap-x-12">
                    <div className="mb-12 md:mb-0">
                        <div className="mb-6 flex justify-center">
                            <img src={FemaleAvatar}
                                className="w-32 rounded-full shadow-lg dark:shadow-black/20" />
                        </div>
                        <h5 className="mb-2 text-lg font-bold">Sharon</h5>
                        <h6 className="mb-2 font-medium text-primary dark:text-primary-400">
                            Perfect!
                        </h6>
                        <p className="mb-4 text-sm">
                            I bought this for my Granddaughter who suffers badly, this is perfect as she doesn't have to carry a water bottle anymore
                            and she can wear this for school also, happy Granddaughter!
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="inline-block w-6">
                                <path fill="currentColor"
                                    d="M580 556h160V396H580v160Zm-360 0h160V396H220v160Zm406 220 80-160H520V336h280v288l-76 152h-98Zm-360 0 80-160H160V336h280v288l-76 152h-98Zm34-300Zm360 0Z" />
                            </svg>
                        </p>
                        <ul className="mb-0 flex justify-center">
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
                                    <path fill={starColor}
                                        d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                </svg>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
                                    <path fill={starColor}
                                        d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                </svg>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
                                    <path fill={starColor}
                                        d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                </svg>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
                                    <path fill={starColor}
                                        d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                </svg>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
                                    <path fill={starColor}
                                        d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                </svg>
                            </li>
                        </ul>
                    </div>
                    <div className="mb-12 md:mb-0">
                        <div className="mb-6 flex justify-center">
                            <img src={JayaAvatar}
                                className="w-32 rounded-full shadow-lg dark:shadow-black/20" />
                        </div>
                        <h5 className="mb-2 text-lg font-bold">Jaya</h5>
                        <h6 className="mb-2 font-medium text-primary dark:text-primary-400">
                            Amazing!
                        </h6>
                        <p className="mb-4 text-sm">
                            Such a good product, for a great price. I love how you can use the massaging and heat setting together or on their own. The strap is easily adjustable and comfy. Also it's discreet enough to wear under clothes when out!
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="inline-block w-6">
                                <path fill="currentColor"
                                    d="M580 556h160V396H580v160Zm-360 0h160V396H220v160Zm406 220 80-160H520V336h280v288l-76 152h-98Zm-360 0 80-160H160V336h280v288l-76 152h-98Zm34-300Zm360 0Z" />
                            </svg>
                        </p>
                        <ul className="mb-0 flex justify-center">
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
                                    <path fill={starColor}
                                        d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                </svg>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
                                    <path fill={starColor}
                                        d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                </svg>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
                                    <path fill={starColor}
                                        d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                </svg>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
                                    <path fill={starColor}
                                        d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                </svg>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
                                    <path fill={starColor}
                                        d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                </svg>
                            </li>

                        </ul>
                    </div>
                    <div className="mb-0">
                        <div className="mb-6 flex justify-center">
                            <img src={KiskeAvatar}
                                className="w-32 rounded-full shadow-lg dark:shadow-black/20" />
                        </div>
                        <h5 className="mb-2 text-lg font-bold">Kiske</h5>
                        <h6 className="mb-2 font-medium text-primary dark:text-primary-400">
                            10/10
                        </h6>
                        <p className="mb-4 text-sm">
                            Amazing thing to have for cramps or back pains. Both functions work at the same time which makes it even better. Adjustable strap and soft fabric. Highly recommend!
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="inline-block w-6">
                                <path fill="currentColor"
                                    d="M580 556h160V396H580v160Zm-360 0h160V396H220v160Zm406 220 80-160H520V336h280v288l-76 152h-98Zm-360 0 80-160H160V336h280v288l-76 152h-98Zm34-300Zm360 0Z" />
                            </svg>
                        </p>
                        <ul className="mb-0 flex justify-center">
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
                                    <path fill={starColor}
                                        d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                </svg>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
                                    <path fill={starColor}
                                        d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                </svg>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
                                    <path fill={starColor}
                                        d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                </svg>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
                                    <path fill={starColor}
                                        d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                </svg>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-warning">
                                    <path fill={starColor}
                                        d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                </svg>
                            </li>
                        </ul>

                    </div>
                </div>
            </section>
        </div>

    )
}

export default Testimonials
