import React from 'react'

const Contact = () => {
    return (
        <div name='contact' className='flex w-full h-screen justify-center items-center p-4 bg-gradient-to-r from-gray-600 via-[#0a192f] to-gray-700 background-animate'>
            <form method='post' action='https://getform.io/f/c57a04fc-0b4e-4b9c-9c9a-81bbef99307f' className='flex flex-col max-w-[600px] w-full'>
                <div className='pb-8 w-full'>
                    <p className='text-3xl lg:text-6xl font-bold text-center text-white border-b-1 border-[#f78b8b] pb-2 mx-16'>Contact Us</p>
                    <p className='text-sm font-light text-center text-gray-50 mt-4 mb-1'>Submit the form below or you can contact us via email</p>
                    <p className='text-sm font-light text-center text-[#f78b8b] mt-1 mb-2'>sales@ensadist.com</p>
                </div>
                <input className='p-2 bg-gray-100 rounded-sm' type="text" placeholder='Name' name='name' />
                <input className='my-4 p-2 bg-gray-100 rounded-sm' type="email" placeholder='Email' name='email' />
                <textarea className='p-2 bg-gray-100 rounded-sm py-12' name='message' row='30' placeholder='Message'></textarea>
                <button className='text-white border-2 rounded-sm hover:bg-[#f78b8b]/80 hover:border-gray-100 px-8 py-2 my-8 mx-auto flex items-center'>Send</button>

            </form>



        </div>
    )
}

export default Contact
