import React from 'react';
import { HiOutlineGift, HiOutlineAdjustments, HiOutlineFire, HiOutlineThumbUp, HiOutlineClock } from 'react-icons/hi';

const FeaturesMin = () => {
    return (
        <div className="lg:hidden md:hidden flex justify-center items-center bg-gradient-to-r from-gray-600 via-[#0a192f] to-gray-700 background-animate shadow-xl">

            <div className="grid grid-col-2 grid-rows-5 pb-8 mr-8 ml-8 align-middle items-center">

                <div className='text-3xl text-center font-bold text-white'>
                    Features
                </div>


                <div className="text-sm font-semibold text-[#f78b8b] py-2 flex items-center">
                    <HiOutlineGift size={30} className="mr-6 text-white flex-shrink-0" />
                    <div className="flex-grow">
                        Premium Comfort
                        <div className="text-sm font-light text-white">
                            The perfect gift to make any woman feel pampered and cherished.
                        </div>
                    </div>
                </div>

                <div className="text-sm font-semibold text-[#f78b8b] pb-1 flex items-center">
                    <HiOutlineAdjustments size={30} className="mr-6 text-white flex-shrink-0" />
                    <div className="flex-grow">
                        Customizable Relaxation
                        <div className="font-light text-white pt-1">
                            Upgrade your relaxation game with adjustable vibration and temperature settings.
                        </div>
                    </div>
                </div>

                <div className="text-sm font-semibold text-[#f78b8b] pb-1 flex items-center">
                    <HiOutlineFire size={30} className="mr-6 text-white flex-shrink-0" />
                    <div className="flex-grow">
                        Portable Pain Relief
                        <div className="font-light text-white pt-1">
                            Take control of menstrual cramps anytime, anywhere with portable heat therapy.
                        </div>
                    </div>
                </div>

                <div className="text-sm font-semibold text-[#f78b8b] pb-1 flex items-center">
                    <HiOutlineThumbUp size={30} className="mr-6 text-white flex-shrink-0" />
                    <div className="flex-grow">
                        Perfect Fit
                        <div className="font-light text-white pt-1">
                            Our easily adjustable elastic strap ensures a comfortable fit for all sizes.
                        </div>
                    </div>
                </div>

                <div className="text-sm font-semibold text-[#f78b8b] pb-1 flex items-center">
                    <HiOutlineClock size={30} className="mr-6 text-white flex-shrink-0" />
                    <div className="flex-grow">
                        Long-lasting Performance
                        <div className="font-light text-white pt-1">
                            Powered by lithium technology, our device offers excellent battery life for uninterrupted relief.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeaturesMin;
