import React, { useState } from 'react'

import { FaBars, FaTimes, FaInstagram, FaTiktok, FaFacebook, FaAmazon } from 'react-icons/fa'
import Logo from '../assets/Logo_T.png'
import { Link } from 'react-scroll'

const Navbar = () => {
    const [nav, setNav] = useState(false)
    const handleClick = () => setNav(!nav)
    return (
        <div className='fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#0a192f] text-gray-300 z-50'>
            <div>
                <img src={Logo} alt="Logo image" style={{ width: '50px' }} />
            </div>

            {/* Menu */}

            <ul className='hidden md:flex'>
                <li>
                    <Link to="home" smooth={true} duration={500}>
                        Home
                    </Link>
                </li>
                <li>
                    <Link to="about" isDynamic={true} smooth={true} duration={500}>
                        About
                    </Link>
                </li>
                <li>
                    <Link to="contact" smooth={true} duration={500}>
                        Contact
                    </Link>
                </li>

            </ul>





            {/* Hamburger */}
            <div onClick={handleClick} className='md:hidden z-10'>
                {!nav ? <FaBars /> : <FaTimes />}
            </div>

            {/* Mobile menu */}

            <ul className={!nav ? 'hidden' : 'absolute top-0 left-0 w-full h-screen bg-[#0a192f] text-gray-300 flex flex-col justify-center items-center'}>
                <li className='py-6 text-4xl'>
                    <Link onClick={handleClick} to="home" smooth={true} duration={500}>
                        Home
                    </Link>
                </li>

                <li className='py-6 text-4xl'>
                    <Link onClick={handleClick} to="about" smooth={true} duration={500}>
                        About
                    </Link>
                </li>
                <li className='py-6 text-4xl'>
                    <Link onClick={handleClick} to="contact" smooth={true} duration={500}>
                        Contact
                    </Link>
                </li>
            </ul>

            {/* Social media icons */}
            <div className='hidden lg:flex fixed flex-col top-[35%] left-0 duration-100'>
                <ul>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#F77737]'>
                        <a className='flex justify-between items-center w-full text-gray-300'
                            href="https://www.amazon.co.uk/gp/product/B0BTMVRHZ7" target="_blank" rel="noreferrer">
                            Shop <FaAmazon size={30} />
                        </a>
                    </li>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600'>
                        <a className='flex justify-between items-center w-full text-gray-300'
                            href="https://www.facebook.com/ENSA.UK" target="_blank" rel="noreferrer">
                            Facebook <FaFacebook size={30} />
                        </a>
                    </li>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-gradient-to-r from-[#405DE6] via-[#FD1D1D] to-[#F56040] '>
                        <a className='flex justify-between items-center w-full text-gray-300'
                            href="https://www.instagram.com/ensa_uk/" target="_blank" rel="noreferrer">
                            Instagram <FaInstagram size={30} />
                        </a>
                    </li>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#ff0050] '>
                        <a className='flex justify-between items-center w-full text-gray-300'
                            href="/">
                            TikTok <FaTiktok size={30} />
                        </a>
                    </li>

                </ul>
            </div>
        </div >
    )
}

export default Navbar
