import React from 'react'

import ImageOne from '../assets/render1.png'
import ImageTwo from '../assets/render2.png'
import ImageThree from '../assets/render3.png'
import Gallery from './Gallery'

const FeatureMax = () => {
    return (
        <div className='hidden md:flex py-12 justify-center bg-gradient-to-r from-gray-600 via-[#0a192f] to-gray-700 background-animate shadow-xl' >


            <div className='grid grid-cols-2 w-full'>
                <div className="col-span-2 justify-center items-center text-center">
                    <h1 className="mb-1 mt-2 text-3xl text-white font-bold lg:text-6xl lg:mb-2">Features</h1>
                    <h1 className="mb-8 mt-2 text-2xl text-[#f78b8b] font-light lg:text-4xl lg:mb-12">What can you expect?</h1>
                </div>
                <div className='col-span-1 ml-8 lg:ml-24' >
                    <Gallery />
                </div>
                <div className='col-span-1 bg-white/05 rounded-sm mt-3 mb-3 mr-8 lg:mr-24'>
                    <div className='grid grid-col-1 grid-rows-5 py-8 mr-8 ml-10 align-middle'>

                        <div className='text-l font-semibold text-[#f78b8b] pb-6'>
                            Premium Comfort <div className='font-light text-white pt-1'>Indulge in luxurious softness and breathability with our premium quality fabrics. The perfect gift to make any woman feel pampered and cherished.</div>
                        </div>

                        <div className='text-l font-semibold text-[#f78b8b] pb-6'>
                            Customizable Relaxation <div className='font-light text-white pt-1'>Upgrade your relaxation game with adjustable vibration frequencies and temperature settings. Experience a personalized and unparalleled level of comfort and relaxation.</div>
                        </div>

                        <div className='text-l font-semibold text-[#f78b8b] pb-6'>
                            Portable Pain Relief <div className='font-light text-white pt-1'>Take control of menstrual cramps anytime, anywhere with our portable heat therapy device. Enjoy soothing warmth on-the-go for ultimate convenience and comfort.</div>
                        </div>

                        <div className='text-l font-semibold text-[#f78b8b] pb-6'>
                            Perfect Fit <div className='font-light text-white pt-1'>Our easily adjustable elastic strap ensures a comfortable fit for all sizes. Experience maximum comfort without any pinching or discomfort.</div>
                        </div>

                        <div className='text-l font-semibold text-[#f78b8b] pb-6'>
                            Long-lasting Performance <div className='font-light text-white pt-1'>Powered by a lithium battery, our device offers excellent battery life for uninterrupted relief. The automatic power-off feature ensures worry-free usage and prolonged durability.</div>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default FeatureMax
