
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Testimonials from "./components/Testimonials";
import FeatureMax from './components/FeatureMax';
import FeaturesMin from "./components/FeaturesMin";
import About from "./components/About"
import Contact from "./components/Contact";

import Logo from './assets/Logo_T.png'



function App() {
  return (

    <div className="">

      <Navbar />
      <Home />
      <FeaturesMin />
      <FeatureMax />
      {/* Customer testimonials
        FORCE SCROLLING ABILITY AFTER A CERTAIN LIMIT TO STOP SECTIONS OVERLAPPING
      */}
      <Testimonials />
      <About />
      <Contact />
    </div>
  );
}

export default App;
