import React from 'react'
import Logo from '../assets/Logo_T2.png'
import { HiOutlineGift, HiOutlineTruck, HiOutlineStar, HiOutlineChatAlt2 } from 'react-icons/hi';
import { Link } from 'react-scroll'


const About = () => {
  return (
    <div name='about' className='flex flex-col w-full h-fit lg:h-screen justify-center items-center align-middle bg-gradient-to-r from-gray-600 via-[#0a192f] to-gray-700 background-animate'>

      <div className='justify-center text-center text-white font-bold text-3xl pt-6 md:text-5xl md:pt-8 lg:text-6xl lg:pt-6' id='AboutTitle'>
        About Us
      </div>



      <div className='flex flex-row justify-center items-center w-full'>
        <div className="basis-full justify-center  mx-8 my-3 lg:max-w-3xl">

          <div className="text-l font-semibold text-[#f78b8b] py-8 flex items-center">
            <HiOutlineGift size={30} className="mr-6 text-white flex-shrink-0 lg:hidden" />
            <div className="flex-grow text-sm md:text-base lg:text-lg lg:text-center md:text-left text-left">
              Welcome to ENSA
              <div className="font-light text-white">
                Your online destination for exceptional products. Based in the United Kingdom, we are dedicated to offering you the very best in quality, reliability, and customer satisfaction.
              </div>
            </div>
          </div>

          <div className="text-l font-semibold text-[#f78b8b] py-8 flex items-center">
            <HiOutlineTruck size={30} className="mr-6 text-white flex-shrink-0 lg:hidden" />
            <div className="flex-grow text-sm md:text-base lg:text-lg lg:text-center  md:text-left text-left">
              Trust
              <div className="font-light text-white">
                At Ensa, we understand that finding the perfect product can be a daunting task. That's why we go above and beyond to source only the finest products from trusted suppliers around the world.
              </div>
            </div>
          </div>
          <div className="text-l font-semibold text-[#f78b8b] py-8 flex items-center">
            <HiOutlineStar size={30} className="mr-6 text-white flex-shrink-0 lg:hidden" />
            <div className="flex-grow text-sm md:text-base lg:text-lg lg:text-center md:text-left text-left">
              Excellence
              <div className="font-light text-white">
                Our team of experts carefully curates each item, ensuring that every product we offer meets our stringent standards of excellence.
              </div>
            </div>
          </div>
          <div className="text-l font-semibold text-[#f78b8b] py-8 flex items-center">
            <HiOutlineChatAlt2 size={30} className="mr-6 text-white flex-shrink-0 lg:hidden" />
            <div className="flex-grow text-sm md:text-base lg:text-lg lg:text-center md:text-left text-left">
              Shop with condifence
              <div className="font-light text-white ">
                Outstanding customer service with prompt next day delivery! Your satisfaction matters the most to us, do not hestitate to<span className='font-bold'> <Link to="contact" smooth={true} duration={500}>contact our friendly staff for any assistance</Link>.</span>

              </div>
            </div>
          </div>





          {/** */}



        </div >

      </div>

    </div >



  )
}

export default About
